export const DeliveryStates = {
  Scheduled: 1,
  Concept: 2,
  Overdue: 3,
  Completed: 4
}

export const DeliveryStateLabels = {
  1: 'Ingepland',
  2: 'Concept',
  3: 'Verlopen',
  4: 'Voltooid'
}

export const DeliveryStateLabelColors = {
  1: '#2ea3f2',
  2: '#ffc107',
  3: '#ff0000',
  4: 'green'
}

export const OpenDeliveryStates = [
  DeliveryStates.Scheduled,
  DeliveryStates.Concept
]

export const ClosedDeliveryStates = [
  DeliveryStates.Overdue,
  DeliveryStates.Completed
]
