import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import DeliveryModel from '@/models/DeliveryModel'
import TableSettings from '@/types/tableSettings'
import { notification } from 'ant-design-vue'
import { WrappedResponse } from 'vue-api-query'
import { useRouter } from 'vue-router'
import { authStore } from '@/stores/auth'
import { ClosedDeliveryStates, OpenDeliveryStates } from '@/helpers/deliveryStates'
import { Delivery } from '@/types/delivery'
import axios from 'axios'

export const deliveryStore = defineStore('delivery', () => {
  const router = useRouter()
  const loading = ref({ list: false, item: false, saving: false, updating: false })
  const deliveries = ref<WrappedResponse<DeliveryModel[]>|DeliveryModel[]>({
    data: [],
    meta: {
      per_page: 25,
      total: 0
    }
  })
  const delivery = ref<Delivery|null>(null)

  const deliveryClosed = computed(() => {
    if (delivery.value) {
      return ClosedDeliveryStates.includes(delivery.value.delivery_status)
    }
    return false
  })

  function getDeliveries (tableSettings:TableSettings) : void {
    loading.value.list = true
    let deliveryModel = new DeliveryModel()

    // Get auth user deliveries.
    deliveryModel = deliveryModel.where('delivery_user.id', authStore().user.id)

    // Add filters if there are any filters set.
    if (tableSettings.activeFilters) {
      Object.keys(tableSettings.activeFilters).forEach(key => {
        if (tableSettings.activeFilters[key].length) {
          deliveryModel = deliveryModel.whereIn(key, tableSettings.activeFilters[key])
        }
      })
    }

    // Check if only archived deliveries have to be shown.
    if (tableSettings.archived) {
      deliveryModel = deliveryModel.whereIn('delivery_status', ClosedDeliveryStates)
    } else {
      deliveryModel = deliveryModel.whereIn('delivery_status', OpenDeliveryStates)
    }

    // Add search if available.
    if (tableSettings.search) {
      deliveryModel = deliveryModel.where('search', tableSettings.search)
    }

    // Add orderBy if sort is set.
    if (tableSettings.sort && tableSettings.sort.order && tableSettings.sort.columnKey) {
      deliveryModel = deliveryModel.orderBy(tableSettings.sort.order === 'ascend' ? tableSettings.sort.columnKey : '-' + tableSettings.sort.columnKey)
    }

    if (tableSettings.pagination) {
      deliveryModel = deliveryModel.limit(tableSettings.pagination.pageSize).page(tableSettings.pagination.current)
    }

    deliveryModel.get().then((r) => {
      deliveries.value = r
    }).catch(() => {
      notification.error({ message: 'Fout tijdens het ophalen van de keuringen!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
    }).finally(() => {
      loading.value.list = false
    })
  }

  function getDelivery (id:string) : void {
    const deliveryModel = new DeliveryModel()

    deliveryModel.find(id).then((r:any) => {
      delivery.value = r.data
    }).catch((e:{ response: { status:number } }) => {
      if (e.response.status === 403) {
        notification.warning({
          message: 'U heeft niet de juiste rechten!',
          description: 'U heeft niet de juiste rechten om deze keuring te bekijken.',
          duration: 5
        })
      } else {
        notification.error({
          message: 'Fout tijdens het ophalen van deze keuring!',
          description: 'Er is iets mis gegaan. Probeer het later nog een keer.'
        })
      }
      router.push({ name: 'Deliveries' })
    })
  }

  function updateDelivery (field:string, value:string|number|null) : void {
    loading.value.updating = true
    deliveryStore().delivery[field] = value
    axios.patch(process.env.VUE_APP_API_URL + '/deliveries/' + delivery.value?.id, { [field]: value }).then((r) => {
      delivery.value = r.data.data
    }).finally(() => {
      loading.value.updating = false
    })
  }

  function completeInspection () : void {
    loading.value.saving = true
    axios.post(process.env.VUE_APP_API_URL + '/deliveries/' + delivery.value?.id + '/complete', { type: 'inspection' }).then(() => {
      router.push({ name: 'Deliveries' })
      notification.success({
        message: 'Voorschouw voltooid.',
        description: 'De voorschouw is succesvol voltooid.',
        duration: 5
      })
    }).finally(() => {
      loading.value.saving = false
    })
  }

  function completeDelivery (signatures:{ signature:File, user:{ id:number, full_name:string, email:string } }[]) : void {
    loading.value.saving = true
    const formData = new FormData()
    formData.append('type', 'delivery')

    signatures.forEach((s:{ signature:File, user:{ id:number, full_name:string, email:string } }, index:number) => {
      formData.append('signatures[' + index + '][user][id]', s.user.id.toString())
      formData.append('signatures[' + index + '][user][full_name]', s.user.full_name)
      formData.append('signatures[' + index + '][user][email]', s.user.email)
      formData.append('signatures[' + index + '][file_name]', s.signature.name)
      formData.append('signatures[' + index + '][file]', s.signature)
    })

    axios.post(process.env.VUE_APP_API_URL + '/deliveries/' + delivery.value?.id + '/complete', formData).then((r) => {
      router.push({ name: 'Deliveries' })
      notification.success({
        message: 'Oplevering voltooid.',
        description: 'De oplevering is succesvol opgeslagen.',
        duration: 5
      })
    }).catch(() => {
      notification.error({
        message: 'Fout tijdens het opslaan van de oplevering!',
        description: 'Er is iets mis gegaan. Probeer het later nog een keer.'
      })
    }).finally(() => {
      loading.value.saving = false
    })
  }

  function updateAnswer (id:number, answer:{ answer:string|null, note:string|null }) :void {
    axios.post(process.env.VUE_APP_API_URL + '/deliveries/answers/' + id + '/update', answer).then((r) => {
      delivery.value = r.data.data
    })
  }

  return { deliveries, delivery, deliveryClosed, loading, getDeliveries, getDelivery, updateDelivery, completeInspection, completeDelivery, updateAnswer }
})
